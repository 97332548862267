import React from "react"
import Seo from "../components/Seo"
const contact = () => {
  return (
    <>
      <h2>contact page</h2>
    </>
  )
}

export default contact
